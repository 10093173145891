@import "scss/variables";

.ConfirmConnection {
  max-width: $screen-md;
  margin-left: auto;
  margin-right: auto;
}

.RemoteErrors {
  margin: $spacing-md;
}

.SwitchAccount {
  margin-top: $spacing-lg;
}
