@import "scss/variables";

$header-height: 69px;
$footer-height: 60px;

.SignUpTemplate {

}

.Header {
  position: fixed;
  top: 0;
  z-index: 100;
}

.Wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  z-index: 0;
  padding: $header-height $spacing-md $spacing-md;

  @include sm {
    // push down footer
    min-height: calc(100vh - #{$footer-height});
  }

  @include lg {
    padding: $header-height $spacing-lg $spacing-lg;
  }
}

.Title, .Main {
  width: 100%;
  max-width: $screen-sm;
  margin: 0 auto;
  text-align: center;
}

.Title {
  margin: $spacing-lg auto;
  text-align: center;

  @media all and (max-width: ($screen-lg - 1px)) {
    font-size: 28px;
  }
}
