@import "scss/variables";

.SignUpForm {
  text-align: left;
  :global(.MuiFormControl-root) {
    margin-bottom: $spacing-xs;
  }
}

.RadioTileGroupLabel {
  margin-bottom: $spacing-md;
}

.RadioTileGroup {
  margin: -8px;
  > * {
    margin: 8px;
  }
}

.NonFieldError {
  margin-bottom: $spacing-lg;
}

.SignInPrompt, .Legals {
  margin: $spacing-lg $spacing-lg 0;
  text-align: center;
}

.DisabledLink {
  opacity: 0.5;
  pointer-events: none;
}

.CountrySelect {
  margin-bottom: $spacing-md;
}

.RecaptchaAlert {
  margin-bottom: $spacing-md;
}
