@import "scss/variables";

.ResetPasswordForm {
  text-align: left;
}

.FormControl {
  margin-bottom: $spacing-xs;
}

.NonFieldError {
  margin-bottom: $spacing-lg;
}

.RecaptchaAlert {
  margin-bottom: $spacing-md;
}
