@import "scss/variables";

.ContinueWithWix {
  //margin: $spacing-md 0;
  text-align: center;
  border-color: #000624;
}

.WixLogo {
  height: 13px;
  margin-right: 18px;
}
