@import "scss/variables";

.PageHeader {
  border-bottom: 1px solid get-color('grey-100');
  padding: $spacing-md;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  min-height: 69px;

  @include lg {
    padding: $spacing-md $spacing-lg;
  }
}

.Nav {
  display: none;
  @include sm {
    display: block;
    margin: 0 (-$spacing-xs);
    & > * {
      margin-left: $spacing-xs !important;
      margin-right: $spacing-xs !important;
    }
  }
}
