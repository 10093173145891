@import "scss/variables";

.AppConnectionSymbol {
  display: flex;
  flex-flow: row nowrap;
  width: 252px;
  align-items: center;
}

.IconWrapper {
  flex: 0 0 64px;
  width: 64px;
  height: 64px;
  padding: 16px;
  border: 1px dashed get-color('grey-100');
  border-radius: 100%;
  > * {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.Coupler {
  flex: 1 1 auto;
  border-bottom: 1px dashed get-color('grey-100');
}
