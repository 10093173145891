@import "scss/variables";

.PageFooter {
  padding: 0 $spacing-md;

  @include sm {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 $spacing-lg;
  }

  @include md {
    padding: 0 (3 * $spacing-lg);
  }
}

.Nav, .Social {
  display: flex;
  flex-flow: row nowrap;
  margin: $spacing-md (-$spacing-md);
  > * {
    margin: 0 $spacing-md;
  }
}
