@import "scss/variables";

.SignInForm {
  text-align: left;
}

.FormControl {
  margin-bottom: $spacing-xs;
}

.NonFieldError {
  margin-bottom: $spacing-lg;
}

.ResetPassword {
  text-align: right;
  margin-bottom: $spacing-lg;
}

.SignUpPrompt {
  margin: $spacing-lg $spacing-lg 0;
  text-align: center;
}

.DisabledLink {
  opacity: 0.5;
  pointer-events: none;
}

.RecaptchaAlert {
  margin-bottom: $spacing-md;
}
