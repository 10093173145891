@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
@import "~normalize.css/normalize.css";

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  background: #fff;
}
