@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap);
*,*::after,*::before{box-sizing:border-box}body{font-family:'Inter', sans-serif;background:#fff}

.AuthorizationCheckWarning_AuthorizationCheckWarning__-tIGh{max-width:576px;margin:0 auto}

.ConfirmConnection_ConfirmConnection__2zLtF{max-width:768px;margin-left:auto;margin-right:auto}.ConfirmConnection_RemoteErrors__2EUi1{margin:16px}.ConfirmConnection_SwitchAccount__2imZp{margin-top:32px}

.CreateStoreForm_CreateStoreForm__1WJ4d{width:600px;max-width:90vw;margin:0 auto}.CreateStoreForm_CardContent__4Rk4P{display:-webkit-flex;display:flex;-webkit-flex-flow:column nowrap;flex-flow:column nowrap;grid-gap:32px;gap:32px}

.ResetPasswordForm_ResetPasswordForm__R35gR{text-align:left}.ResetPasswordForm_FormControl__2zShx{margin-bottom:4px}.ResetPasswordForm_NonFieldError__j5ren{margin-bottom:32px}.ResetPasswordForm_RecaptchaAlert__cJ65X{margin-bottom:16px}

.ResetPasswordSuccessMessage_SuccessIcon__30f-G{display:block;margin:0 auto;font-size:68px}.ResetPasswordSuccessMessage_ActionButton__1Ytsd{margin-top:24px}

.ContinueWithWixButton_ContinueWithWix__1DPqs{text-align:center;border-color:#000624}.ContinueWithWixButton_WixLogo__2xTNP{height:13px;margin-right:18px}

.FormHeader_FormHeader__2clWr{text-align:center}

.PageFooter_PageFooter__33_40{padding:0 16px}@media (min-width: 576px){.PageFooter_PageFooter__33_40{display:-webkit-flex;display:flex;-webkit-flex-flow:row nowrap;flex-flow:row nowrap;-webkit-justify-content:space-between;justify-content:space-between;-webkit-align-items:center;align-items:center;padding:0 32px}}@media (min-width: 768px){.PageFooter_PageFooter__33_40{padding:0 96px}}.PageFooter_Nav__1k4ux,.PageFooter_Social__lrYGK{display:-webkit-flex;display:flex;-webkit-flex-flow:row nowrap;flex-flow:row nowrap;margin:16px -16px}.PageFooter_Nav__1k4ux>*,.PageFooter_Social__lrYGK>*{margin:0 16px}

.PageHeader_PageHeader__1IYLl{border-bottom:1px solid #E7E9EC;padding:16px;width:100%;background-color:#fff;display:-webkit-flex;display:flex;-webkit-flex-flow:row wrap;flex-flow:row wrap;-webkit-justify-content:space-between;justify-content:space-between;-webkit-align-items:center;align-items:center;min-height:69px}@media (min-width: 992px){.PageHeader_PageHeader__1IYLl{padding:16px 32px}}.PageHeader_Nav__2GdG5{display:none}@media (min-width: 576px){.PageHeader_Nav__2GdG5{display:block;margin:0 -4px}.PageHeader_Nav__2GdG5>*{margin-left:4px !important;margin-right:4px !important}}

.SignInForm_SignInForm__KrzkN{text-align:left}.SignInForm_FormControl__gaCpo{margin-bottom:4px}.SignInForm_NonFieldError__3j5e7{margin-bottom:32px}.SignInForm_ResetPassword__2iw1A{text-align:right;margin-bottom:32px}.SignInForm_SignUpPrompt__1kl-Y{margin:32px 32px 0;text-align:center}.SignInForm_DisabledLink__3tviN{opacity:0.5;pointer-events:none}.SignInForm_RecaptchaAlert__1I74X{margin-bottom:16px}

.SignUpForm_SignUpForm__vCKeN{text-align:left}.SignUpForm_SignUpForm__vCKeN .MuiFormControl-root{margin-bottom:4px}.SignUpForm_RadioTileGroupLabel__3EdE_{margin-bottom:16px}.SignUpForm_RadioTileGroup__Oj4zV{margin:-8px}.SignUpForm_RadioTileGroup__Oj4zV>*{margin:8px}.SignUpForm_NonFieldError__2F72J{margin-bottom:32px}.SignUpForm_SignInPrompt__1-PF_,.SignUpForm_Legals__1hQ2y{margin:32px 32px 0;text-align:center}.SignUpForm_DisabledLink__3Q05D{opacity:0.5;pointer-events:none}.SignUpForm_CountrySelect__JrOpM{margin-bottom:16px}.SignUpForm_RecaptchaAlert__1Cfho{margin-bottom:16px}

.SwitchAccount_SwitchAccount__21M3R{color:#86909E}

.AppConnectionSymbol_AppConnectionSymbol__3pK95{display:-webkit-flex;display:flex;-webkit-flex-flow:row nowrap;flex-flow:row nowrap;width:252px;-webkit-align-items:center;align-items:center}.AppConnectionSymbol_IconWrapper__18WEa{-webkit-flex:0 0 64px;flex:0 0 64px;width:64px;height:64px;padding:16px;border:1px dashed #E7E9EC;border-radius:100%}.AppConnectionSymbol_IconWrapper__18WEa>*{display:block;width:100%;height:100%}.AppConnectionSymbol_Coupler__3x4hT{-webkit-flex:1 1 auto;flex:1 1 auto;border-bottom:1px dashed #E7E9EC}

.ConnectAppTemplate_Header__3R0RX{position:fixed;top:0;z-index:100}.ConnectAppTemplate_Wrapper__2TRMV{display:-webkit-flex;display:flex;-webkit-flex-flow:column nowrap;flex-flow:column nowrap;-webkit-align-items:center;align-items:center;z-index:0;padding:69px 16px 16px}@media (min-width: 576px){.ConnectAppTemplate_Wrapper__2TRMV{min-height:calc(100vh - 60px)}}@media (min-width: 992px){.ConnectAppTemplate_Wrapper__2TRMV{padding:69px 32px 32px}}.ConnectAppTemplate_BeforeTitle__1GS2A{margin:32px auto 16px}.ConnectAppTemplate_Title__2vb6j,.ConnectAppTemplate_Main__2b583{width:100%;max-width:992px;margin:8px auto;text-align:center}.ConnectAppTemplate_Title__2vb6j{margin-bottom:32px;text-align:center}@media all and (max-width: 991px){.ConnectAppTemplate_Title__2vb6j{font-size:28px}}.ConnectAppTemplate_Columns__2IZUv{text-align:left}.ConnectAppTemplate_Columns__2IZUv>.ConnectAppTemplate_ColDivider__2jBHo{margin:32px 0}@media (min-width: 992px){.ConnectAppTemplate_Columns__2IZUv{display:-webkit-flex;display:flex;-webkit-flex-flow:row nowrap;flex-flow:row nowrap}.ConnectAppTemplate_Columns__2IZUv>.ConnectAppTemplate_Col__2zWLV{-webkit-flex:1 1 1%;flex:1 1 1%}.ConnectAppTemplate_Columns__2IZUv>.ConnectAppTemplate_ColDivider__2jBHo{-webkit-flex:0 1 auto;flex:0 1 auto;-webkit-align-self:stretch;align-self:stretch;margin:120px 10%}}

.SignUpTemplate_Header__3IRxf{position:fixed;top:0;z-index:100}.SignUpTemplate_Wrapper__18DCy{display:-webkit-flex;display:flex;-webkit-flex-flow:column nowrap;flex-flow:column nowrap;-webkit-align-items:center;align-items:center;z-index:0;padding:69px 16px 16px}@media (min-width: 576px){.SignUpTemplate_Wrapper__18DCy{min-height:calc(100vh - 60px)}}@media (min-width: 992px){.SignUpTemplate_Wrapper__18DCy{padding:69px 32px 32px}}.SignUpTemplate_Title__2pzt-,.SignUpTemplate_Main__tv_P7{width:100%;max-width:576px;margin:0 auto;text-align:center}.SignUpTemplate_Title__2pzt-{margin:32px auto;text-align:center}@media all and (max-width: 991px){.SignUpTemplate_Title__2pzt-{font-size:28px}}

