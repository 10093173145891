@import "scss/variables";

.CreateStoreForm {
  width: 600px;
  max-width: 90vw;
  margin: 0 auto;
}

.CardContent {
  display: flex;
  flex-flow: column nowrap;
  gap: $spacing-lg;
}
