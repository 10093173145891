@import "scss/variables";

.ResetPasswordSuccessMessage {

}

.SuccessIcon {
  display: block;
  margin: 0 auto;
  font-size: 68px;
}

.ActionButton {
  margin-top: (3 * $spacing-sm);
}
